/**
 * Created by jimmy on 2019/4/5.
 */

import * as Utils from './utils';

let showLoading = true;

export const getCityInfo = () => {
  let mapObj = new window.AMap.Map('iCenter');

  return new Promise((resolve, reject) => {
    mapObj.plugin(['AMap.Geolocation', 'AMap.Geocoder'], function () {
      let geolocation = new window.AMap.Geolocation({
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 10000,          //超过10秒后停止定位，默认：无穷大
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
      });
      geolocation.getCityInfo(function (status, result) {
        console.log('geolocation.getCityInfo');
        console.log('返回IP定位信息结果', status, result);
        if (status == 'complete') {
          resolve({
            ...result,
            resStatus: status
          });
        } else {
          console.error('IP 定位失败', result);
          // 默认返回上海
          resolve({
            resStatus: status
          });
        }
      });
    });
  });
};

export const getCurrentPosition2 = () => {
  let mapObj = new window.AMap.Map('iCenter');

  return new Promise((resolve, reject) => {
    mapObj.plugin(['AMap.Geolocation', 'AMap.Geocoder'], function () {
      let geolocation = new window.AMap.Geolocation({
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 10000,          //超过10秒后停止定位，默认：无穷大
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
      });
      geolocation.getCurrentPosition(function (status, result) {
        console.log('返回精确位置信息结果', status, result);
        if (status == 'complete') {
          const lnglat = [result.position.lng, result.position.lat];
          const geocoder = new window.AMap.Geocoder();
          geocoder.getAddress(lnglat, function(addrStatus, addrResult) {
            console.log('返回精确地址信息结果', addrStatus, addrResult);
            if(addrStatus == 'complete' && addrResult.info && addrResult.info.toLowerCase() == 'ok') {
              resolve({
                ...result,
                resStatus: 'complete',
                lnglat,
                ...addrResult.regeocode.addressComponent,
              });
            }else{
              console.error('返回精确地址信息失败', addrResult);
              resolve({
                ...result,
                resStatus: 'error',
                lnglat
              });
            }
          });
        } else {
          console.error('精确位置信息失败', result);
          // 默认返回上海
          resolve({
            resStatus: status
          });
        }
      });
    });
  });
};

// 路线规划
export const doGuide = async (endLngLat) => {
  const curPoi = await getCurrentPosition2();
  if(curPoi.resStatus == 'error') {
    Utils._alert('定位失败，请使用地图软件直接查找酒店信息');
    return;
  }
  window.location.href = `https://m.amap.com/navigation/buslist/saddr=${curPoi.lnglat.join(',')}&daddr=${endLngLat.join(',')}`;
};

export const addMap = (lnglat, containerId) => {
  new window.AMap.Map(containerId, {
    center: lnglat,
    zoom: 11
  });
};