/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import './index.less';

import * as Utils from '../../common/utils';

import IMG_FOOTER_HOME from '../../common/images/home2.png';
import IMG_FOOTER_HOME_SELECTED from '../../common/images/home2.png';
import IMG_FOOTER_STORE from '../../common/images/store2.png';
import IMG_FOOTER_STORE_SELECTED from '../../common/images/store2.png';
import IMG_FOOTER_ADD from '../../common/images/add.png';

const CompModel = props => {
	const { title = '青浦报订阅服务', style = {} } = props;

	useEffect(() => {
		Utils.setTitle(title);
	}, [123]);

	return (
		<div className="page-container" style={style}>
			{ props.children }
		</div>
	);
};

export default CompModel;
