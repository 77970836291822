/**
 * Created by jimmy on 2019/9/29.
 */

 import React, { useState, useEffect } from 'react';
 import Page from '../../components/page';
 import * as Request from '../../common/request';
 import * as Utils from '../../common/utils';
 import './feedback.less';
import * as AMap from "../../common/amap";
 const Feedback = props => {
  //  submitFlag为true则代表在提交中
   const [submitFlag, setSubmitFlag ] = useState(0);
   const [cityInfo, setCityInfo ] = useState({});
   const [desc, setDesc] = useState('');
   const [nickname, setNickname] = useState('');
   const [mobile, setMobile] = useState('');

   const [ popFlag, setPopFlag ] = useState(false);
   const [ popObj, setPopObj ] = useState({});
   const [ topicList, setTopicList ] = useState([]);
   const [ topic, setTopic ] = useState('');

   const valid = data => {
    if (!data.topicId) {
      Utils.toast.show('请填写问题分类!');
			return ;
    }
    if (!data.nickname) {
			Utils.toast.show('请填写反馈人的姓名!');
			return ;
		}
		const mobile = data.mobile.trim();
		if (!mobile) {
			Utils.toast.show('请填写反馈人的手机号!');
			return ;
		}
		if (mobile && !(/^1[1-9]\d{9}$/.test(mobile))) {
			Utils.toast.show('请填写正确的的手机号!');
			return ;
		}
    return true;
   }

   const getTopicList = async () => {
    Request.get({
      url: '/topic/list'
    }).then(res => {
      setTopicList(res?.data?.list || []);
    })
   }

   const submit = () => {
    //  1S以内只能提交一次
     if (Date.now() - submitFlag < 1000) {
       return false;
     }
     setSubmitFlag(Date.now());
     const param = {
        topicId: topic,
        desc,
        mobile,
        nickname,
       ...cityInfo
     };
     
     if (valid(param)) {
      if (topic == 9999) {
        delete param.topicId;
      } else {
        param.topicId = Number(param.topicId)
      }
       Request.post({
          url: '/feedback/submit',
          data: param,
        }).then(res => {
          if (res?.ok === 0) {
            Utils.toast.show('感谢您的反馈!');
          }
        })
     }
   }

   /**
    * 定位
    */
   const getAddress = async() => {
     const result = await AMap.getCurrentPosition2();
     if(result && result.info && result.info.toLocaleLowerCase() == 'success') {
       setCityInfo({
         province: result.province,
         city: result.city || result.province,
         distinct: result.district,
         township: result.township,
         zipCode: result.adcode,
       });
     }else{
       setCityInfo({
         city: '上海市',
         province: '上海市',
         distinct: '青浦区',
         zipCode: '201799',
       });
     }
   };
   useEffect(() => {
     getAddress();
     getTopicList();
   }, [ ]);

   return (
     <Page {...{ page: 'feedback', title: '意见反馈' }} {...props}>
       <section className="feed-container"
        >
          <div className="block">
            <div className="label">意见反馈<span className="red-star">*</span></div>
            <div className="topic">
              <select className="topic-select" onChange={e => setTopic(e.target.value)}>
              <option value="">--请选择问题分类--</option>
                {
                  topicList.map(item => <option key={item.id} value={item.id}>{item.title}</option>)
                }
                <option value="9999">其他</option>
              </select>
            </div>
            <div className="flex flex-v-h-c">
              <textarea className="textarea" placeholder="请描述您遇到的问题......" onInput={e => setDesc(e.target.value)}></textarea>
            </div>
          </div>

          <div className="block">
            <div className="label bd-b">联系方式<span className="red-star">*</span></div>
            <div className="flex bd-b">
							<div className="label-text">联系人</div>
							<div className="flex1"><input className="input" onInput={e => setNickname(e.target.value)} type="text" placeholder="请填写订阅人姓名" /></div>
						</div>
            <div className="flex">
							<div className="label-text">手机号</div>
							<div className="flex1"><input className="input" maxLength={11} onInput={e => setMobile(e.target.value)} placeholder="请填写订阅人手机号" /></div>
						</div>
          </div>
         <div className="large-btn submit-btn" onClick={submit}>立即提交</div>
         <div className="to-mine" onClick={() => setPopFlag(true)}>我的反馈</div>
       </section>

       {
         popFlag && <section className="popup-container">
           <div className="popup-wrap">
             <div className="title">提示<div className="close" onClick={() => { setPopFlag(false) }} /></div>
             <div className="input-wrap"><input placeholder="请输入提交反馈的手机号码" maxLength={11} onInput={e => {
               setPopObj({
                 ...popObj,
                 mobile: e.target.value
               });
             }} /></div>
             <div className="large-btn submit-btn" onClick={() => {
               if(!popObj.mobile || !(/^1[1-9]\d{9}$/.test(popObj.mobile))) {
                 Utils.toast.show('请输入合法手机号码');
                 return;
               }
               props.history.push(`/feedback/mine?mobile=${popObj.mobile}`);
             }}>提交</div>
           </div>
         </section>
       }
     </Page>
   );
 };

 export default Feedback;
