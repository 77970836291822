import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as Utils from './common/utils';
import './index.less';
import * as serviceWorker from './serviceWorker';

import PageHome from './pages/home';
import PageFeedback from './pages/feedback/feedback'
import PageMyFeedback from './pages/myFeedback'

const App = () => {
	useEffect(() => {
		document.body.addEventListener('focusin', Utils.focusHanlder);
		return () => {
		}
	}, [])

	return <Router>
		<div>
			<Route exact path="/" component={PageHome} />
			{/* <Route exact path="/store" component={PageStore} />
			<Route exact path="/dynamic/:id" component={PageDynamicDetail} /> */}
			<Route exact path="/feedback" component={PageFeedback} />
			<Route exact path="/feedback/mine" component={PageMyFeedback} />
		</div>
	</Router>
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Utils.user.wxLogin({}, () => window.location.reload());
