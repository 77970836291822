/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as Const from '../../common/const';
import Page from '../../components/page';

import './index.less';

import IMG_ZAN from '../../common/images/zan.png';
import IMG_ZAN_SELECTED from '../../common/images/zan-selected.png';
import IMG_READ_COUNT from '../../common/images/read-count.png';
import IMG_POI from '../../common/images/poi.png';
import IMG_AVATAR_DEFAULT from '../../common/images/avatar-default.png';

moment.locale('zh-cn');

const CONST_TABS = [
  { label: '全部', status: 0 },
  { label: '待处理', status: 1 },
  { label: '已处理', status: 2 },
];

const PageModel = props => {
  const mobile = Utils.getSearchByKey('mobile');
  const [ tab, setTab ] = useState({});
  useEffect(() => {
    const status = Utils.getSearchByKey('status');
    let target = CONST_TABS.filter(item => item.status == status);
    target = target && target[0] || CONST_TABS[0];
    setTab(target);
  }, [ window.location.href ]);

  const [ list, setList ] = useState([]);
  const getList = async () => {
    const result = await Request.get({
      url: '/feedback/list',
      data: {
        status: tab.status,
        mobile
      }
    });
    if(!result) return;

    setList(result.data.list);
  };
  useEffect(() => {
    getList();
  }, [ tab ]);

  return (
    <Page title="我的反馈列表" style={{ padding: 0, background: '#f5f5f5' }}>
      <section className="myfeedback-container">
        <div className="tabs">
          {
            CONST_TABS.map((item, idx) => {
              return (
                <div className={`item${ item.status == tab.status ? ' selected' : '' }`} key={idx} onClick={() => {
                  props.history.replace(`/feedback/mine?status=${item.status}&mobile=${mobile}`);
                }}>{item.label}</div>
              )
            })
          }
        </div>
        <div className="msglist-wrap">
          {
            list && list.length > 0 && list.map((item, idx) => {
              const userInfo = item.userInfo || {};
              const userCover = userInfo && userInfo.cover || IMG_AVATAR_DEFAULT;
              return (
                <div className="item" key={idx}>
                  <div className="userinfo">
                    <div className="cover" style={{ backgroundImage: `url(${userCover})` }} />
                    <div className="detail">
                      <span className="name">{userInfo.nickname}</span>
                      <span>{item.createdAt && moment(item.createdAt).format('YYYY/MM/DD')}</span>
                    </div>
                    <div className="status">{({ 1: '待处理', 2: '已处理' })[item.status]}</div>
                  </div>
                  <div className="content">{item.desc}</div>
                  { item.result && <div className="content">处理结果：{item.result}</div> }
                </div>
              )
            }) || <div style={{ marginTop: 206, textAlign: 'center', color: '#999' }} >暂无数据</div>
          }
        </div>
      </section>
    </Page>
  );
};

export default PageModel;
